import logger from '../../logger';
import { getZippedObject, extractFile } from './zip-utils';
import { pickBy, cloneDeep, toPairs, reduce, transform } from 'lodash';
import { API_ENDPOINT, BUCKET_NAME } from '../../config/awsLabelingConfig';
import { get, post, deleteObject } from '../../shared-logic/fetchApi';
import {
  TYPE_COLOR,
  TYPE_XRAY,
  TYPE_NIRI,
  Tasks,
  Tier2NavgiationModes,
} from '../../shared-logic/enums';
import { isTooth, isXRay } from '../../shared-logic/taskLevelsTypesHelper';
import Roles from '../TasksList/rolesEnum';
import { Tiers } from '../../shared-logic/enums';

const ABORT_ERR_NAME = 'AbortError';
const isAbortError = (err) => err?.name === ABORT_ERR_NAME;

const handleFiles = async (zippedNiriFile) => {
  const filteredFiles = pickBy(zippedNiriFile.files, (value) => !value.dir);
  const keysArr = Object.keys(filteredFiles).sort();

  const imagesByKey = await keysArr.reduce(async (acc, key) => {
    const _acc = await acc;
    const [index, objectName] = key.split('/');
    const obj = _acc[index] || {};
    _acc[index] = obj;
    const image = await extractFile(zippedNiriFile, key, 'base64');
    const parsedObjName = objectName.split('.')[0];
    obj[parsedObjName] = image;
    obj.dirName = index;
    return Promise.resolve(_acc);
  }, Promise.resolve({}));
  return Promise.resolve(Object.values(imagesByKey));
};

const handleSingleFiles = async (zippedXRayFile) => {
  const files = zippedXRayFile.files;
  const keysArr = Object.keys(files).sort();

  const imagesByKey = await keysArr.reduce(async (acc, key) => {
    const _acc = await acc;
    const index = key;
    const image = await extractFile(zippedXRayFile, key, 'base64');
    _acc[index] = image;
    return Promise.resolve(_acc);
  }, Promise.resolve({}));

  return Promise.resolve(Object.values(imagesByKey));
};

export async function postData(url = '', data = {}) {
  try {
    const res = await post(url, JSON.stringify(data));
    return res;
  } catch (err) {
    logger
      .error('postData')
      .data({ module: 'LabelingToolPage.logic', err: err })
      .end();
    return Promise.reject(err);
  }
}

async function deleteData(url = '', data = {}) {
  try {
    const res = await deleteObject(url, data);
    return res;
  } catch (err) {
    logger
      .error('deleteData')
      .data({ module: 'LabelingToolPage.logic', err: err })
      .end();
    return Promise.reject(err);
  }
}

const toCamel = (o) => {
  var newO, origKey, newKey, value;
  if (o instanceof Array) {
    return o.map(function (value) {
      if (typeof value === 'object') {
        value = toCamel(value);
      }
      return value;
    });
  } else {
    newO = {};
    for (origKey in o) {
      if (o.hasOwnProperty(origKey)) {
        newKey = (
          origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey
        ).toString();
        value = o[origKey];
        if (
          value instanceof Array ||
          (value !== null && value.constructor === Object)
        ) {
          value = toCamel(value);
        }
        newO[newKey] = value;
      }
    }
  }
  return newO;
};

const setNotes = (data) => {
  return data.map(({ ObjectKey, ...rest }) => {
    const obj = { ...rest, edited: false };
    return transform(
      obj,
      (result, val, key) =>
        (result[key.charAt(0).toLowerCase() + key.slice(1)] = val)
    );
  });
};

const getPointsWithLabeler = (previousTierMarkings, tier) => {
  const points = [];
  // return previousTierMarkings as is since tooth mode has no marks
  if (previousTierMarkings.teeth) {
    return previousTierMarkings.teeth;
  }
  //check if data is related to xray
  if (previousTierMarkings.images) {
    previousTierMarkings.images.forEach((xrayImage) => {
      const xrayClusters = extractClusters({ xray: xrayImage })(TYPE_XRAY);
      points.push({
        id: xrayImage.id,
        consistent: xrayImage.consistent,
        xray: xrayClusters,
      });
    });
  } else {
    if (tier === 3) {
      if (previousTierMarkings.imagePairs) {
        previousTierMarkings.imagePairs.forEach((imagePair) => {
          const colorPoints = imagePair[TYPE_COLOR];
          const niriPoints = imagePair[TYPE_NIRI];

          points.push({
            id: imagePair.id,
            niri: niriPoints,
            color: colorPoints,
          });
        });
      } else {
        //support old format
        Object.keys(previousTierMarkings).forEach((i) => {
          const colorPoints = previousTierMarkings[i][TYPE_COLOR];
          const niriPoints = previousTierMarkings[i][TYPE_NIRI];

          points.push({
            id: Number(
              previousTierMarkings[i].id ? previousTierMarkings[i].id : i
            ),
            niri: niriPoints,
            color: colorPoints,
          });
        });
      }
    } else {
      previousTierMarkings.imagePairs.forEach((imagePair) => {
        const colorClusters = extractClusters(imagePair)(TYPE_COLOR);
        const niriClusters = extractClusters(imagePair)(TYPE_NIRI);
        points.push({
          id: imagePair.id,
          consistent: imagePair.consistent,
          niri: niriClusters,
          color: colorClusters,
        });
      });
    }
  }

  return points;
};

const extractClusters = (imagePair) => (typeArray) =>
  imagePair[typeArray].clusters
    .map((cluster) => {
      const { consistent, inconsistent } = cluster.markings;
      const combinedMarkings = { ...consistent, ...inconsistent };
      const res = reduce(
        toPairs(combinedMarkings),
        addMarkingsObjectToArray.bind(null, cluster.consistent),
        []
      );
      return res;
    })
    .flat();

const addMarkingsObjectToArray = (consistency, markings, pair) => {
  const key = pair[0];
  const val = pair[1];

  val.forEach((mark) =>
    markings.push({ ...mark, labeler: key, consistent: consistency })
  );
  return markings;
};

const labelingToolLogic = {
  initStateImageLevel: {
    scheme: 'JSON',
    imagePairs: [
      {
        id: 0,
        commited: false,
        niri: {
          brightness: 100,
          contrast: 100,
          markings: [],
        },
        color: {
          brightness: 100,
          contrast: 100,
          markings: [],
        },
      },
    ],
  },
  initStateToothLevel: {
    scheme: 'JSON',
    teeth: [],
  },
  initStateXRayLevel: {
    scheme: 'JSON',
    imagePairs: [
      {
        id: 0,
        brightness: 100,
        contrast: 100,
        markings: [],
      },
    ],
  },
  compareMarkers(a, b) {
    return a.id > b.id ? 1 : a.id === b.id ? 0 : -1;
  },

  async getPictures(path, batch, tier, singleFiles = false, signal) {
    try {
      const presignedDataUrl = await get(
        `${API_ENDPOINT}/getPresignedUrl/?bucketName=${BUCKET_NAME}&path=${path}&batch=${batch}&tier=${tier}`,
        signal
      );
      const data = await fetch(presignedDataUrl.url);

      const buffer = await data.arrayBuffer();
      const zipped = await getZippedObject(buffer);
      const unzipped = singleFiles
        ? await handleSingleFiles(zipped)
        : await handleFiles(zipped);
      return unzipped;
    } catch (error) {
      if (isAbortError(error)) throw error;
      logger
        .error('getPictures')
        .data({ module: 'LabelingToolPage.logic', err: error })
        .end();
      return [];
    }
  },

  async getLabelingState({ path, assignee, tier }, signal) {
    const appStateFile = calcAppStateFilename(assignee, parseInt(tier));
    try {
      const labelingState = await get(
        `${API_ENDPOINT}/getState?bucketName=${BUCKET_NAME}&objectKey=${path}/${appStateFile}`,
        signal
      );
      const res = toCamel(labelingState);
      return res;
    } catch (error) {
      if (isAbortError(error)) throw error;
      logger
        .error('getLabelingState')
        .data({ module: 'LabelingToolPage.logic', err: error })
        .end();
      return Promise.reject(null);
    }
  },

  async getPreviousAggregation(path, tier, signal) {
    try {
      const res = await get(
        `${API_ENDPOINT}/getState?bucketName=${BUCKET_NAME}&objectKey=${path}/DataMarkingAggregation-tier${
          tier - 1
        }-V2.json`,
        signal
      );
      const aggregatedPrevState = toCamel(res);

      const points = getPointsWithLabeler(aggregatedPrevState, tier);
      return points;
    } catch (error) {
      if (isAbortError(error)) throw error;
      logger
        .error('getLabelingState')
        .data({ module: 'LabelingToolPage.logic', err: error })
        .end();
      return Promise.reject(null);
    }
  },

  async getDetectionPoints(path, signal) {
    try {
      const res = await get(
        `${API_ENDPOINT}/getState?bucketName=${BUCKET_NAME}&objectKey=${path}`,
        signal
      );
      const response = toCamel(res);
      return response;
    } catch (error) {
      if (isAbortError(error)) throw error;
      logger
        .error('getLabelingState')
        .data({ module: 'LabelingToolPage.logic', err: error })
        .end();
      return Promise.reject(null);
    }
  },

  async getInitialState(task, signal) {
    try {
      return await this.getLabelingState(task, signal);
    } catch (error) {
      if (isAbortError(error)) throw error;
      const loggerMessage =
        'No prior state was found, loading default initial state.';
      logger
        .info(loggerMessage)
        .data({ module: 'LabelingToolPage.logic' })
        .end();
      let defaultState;

      if (isTooth(task.level)) {
        defaultState = this.initStateToothLevel;
      } else if (task.path.includes(Tasks.XRAY)) {
        defaultState = this.initStateXRayLevel;
      } else {
        defaultState = this.initStateImageLevel;
      }

      return cloneDeep(defaultState);
    }
  },

  async saveState(task, output, outputIndex, notes, logs) {
    const data = { output: output, outputIndex: outputIndex, notes: notes };
    if (logs && logs.length > 0) {
      data['logs'] = logs;
    }
    try {
      const { path, assignee, tier } = task;
      const res = await postData(
        `${API_ENDPOINT}/saveState/?bucketName=${BUCKET_NAME}&assignee=${
          assignee.split('@')[0]
        }&tier=${tier}&path=${path}`,
        data
      );
      return res;
    } catch (err) {
      logger
        .error('saveState')
        .data({ module: 'LabelingToolPage.logic', err: err })
        .end();
      return Promise.reject(err);
    }
  },

  async cancelTask(path, cancelIndex) {
    try {
      const data = {
        objectFolder: path,
        bucket: BUCKET_NAME,
        cancelIndex: cancelIndex,
      };
      const res = await deleteData(`${API_ENDPOINT}/deleteTask`, data);
      return res;
    } catch (err) {
      logger
        .error('cancelTask')
        .data({ module: 'LabelingToolPage.logic', err: err })
        .end();
      return Promise.reject(err);
    }
  },

  async potentiallyDeleteRevert(data) {
    try {
      const res = await postData(
        `${API_ENDPOINT}/potentiallyDeleteRevert`,
        data
      );
      return res;
    } catch (err) {
      logger
        .error('returnTaskToWork')
        .data({ module: 'LabelingToolPage.logic', err: err })
        .end();
      return Promise.reject(err);
    }
  },

  async getSupervisorNotes(taskId, type, signal) {
    try {
      const data = await get(
        `${API_ENDPOINT}/getSupervisorNotes/?taskId=${taskId}&type=${type}`,
        signal
      );
      return setNotes(data);
    } catch (err) {
      if (isAbortError(err)) throw err;
      logger
        .error('getSupervisorNotes')
        .data({ module: 'LabelingToolPage.logic', err: err })
        .end();
      return Promise.reject(err);
    }
  },

  getNumCommitted(output) {
    return output.imagePairs.filter((x) => x.commited === true).length;
  },

  calculateNumSteps(
    role,
    step,
    currentTask,
    currentImagePairId,
    output,
    numImages,
    prevTierState,
    mode = Tier2NavgiationModes.CONFLICTS
  ) {
    let numSteps = currentImagePairId;

    const numCommitted = this.getNumCommitted(output);
    if (
      mode === Tier2NavgiationModes.CONFLICTS &&
      numCommitted === numImages &&
      role !== Roles.TIER2 &&
      role !== Roles.TIER3 &&
      role !== Roles.SUPERVISOR &&
      role !== Roles.WATCHER
    ) {
      return currentImagePairId;
    }

    let conditionNotMet = true;
    while (conditionNotMet) {
      numSteps += step;
      if (numSteps < -numImages - 1) {
        numSteps = Math.abs(numSteps + (numImages - 1));
      } else if (numSteps > numImages - 1) {
        numSteps = numSteps - numImages;
      } else if (numSteps < 0 && step === -1) {
        numSteps = numImages - 1;
      }
      if (
        role === Roles.TIER3 ||
        role === Roles.SUPERVISOR ||
        role === Roles.WATCHER
      ) {
        conditionNotMet =
          hasNoMarkings(numSteps, output, currentTask, role) &&
          hasNoMarkings(numSteps, prevTierState, currentTask, role);
      } else {
        conditionNotMet =
          mode === Tier2NavgiationModes.CONFLICTS
            ? role === Roles.TIER2
              ? hasNoConflicts(numSteps, prevTierState, currentTask, role)
              : isCommitted(numSteps, output)
            : hasNoMarkings(numSteps, prevTierState, currentTask, role);
      }
      conditionNotMet &= numSteps !== currentImagePairId;
    }

    return step === -1
      ? -(currentImagePairId - numSteps)
      : numSteps - currentImagePairId;
  },

  async sendLoadingDuration(
    path,
    batch,
    taskId,
    startingTime,
    ip,
    userId,
    type = 'task'
  ) {
    // const filesSize = undefined;
    const duration = Date.now() - startingTime;
    try {
      const data = {
        type,
        userId,
        path,
        batch,
        taskShortName: taskId,
        startingTime,
        duration,
        ip,
        // filesSize,
      };
      await postData(`${API_ENDPOINT}/addLoadingDuration`, data);
    } catch (err) {
      logger
        .error('sendLoadingDuration')
        .data({ module: 'LabelingToolPage.logic', err: err })
        .end();
    }
  },
};

function getMarkings(currentImagePairId, prevTierState, currentTask) {
  const images = prevTierState.imagePairs || prevTierState;

  const imageData = images?.find(({ id }) => id === currentImagePairId);
  if (!imageData) return [];

  if (isXRay(currentTask)) {
    return imageData.xray.length ? false : true;
  }

  const colorMarkings = imageData.color.markings || imageData.color;

  const niriMarkings = imageData.niri.markings || imageData.niri;

  return [...colorMarkings, ...niriMarkings];
}

function hasNoConflicts(currentImagePairId, prevTierState, currentTask) {
  const markings = getMarkings(currentImagePairId, prevTierState, currentTask);
  return markings?.find((point) => !point.consistent) ? false : true;
}

function hasNoMarkings(currentImagePairId, prevTierState, currentTask, role) {
  const markings = getMarkings(currentImagePairId, prevTierState, currentTask);

  if (role === Roles.TIER3) {
    return markings?.find((point) => point.tier === Tiers.TIER_2 || !point.tier)
      ? false
      : true;
  } else {
    return markings && markings.length ? false : true;
  }
}

const isCommitted = (currentImagePairId, output) => {
  const imageData = output.imagePairs.find(
    ({ id }) => id === currentImagePairId
  );
  return imageData ? imageData.commited : false;
};

function calcAppStateFilename(assignee, tier) {
  if (tier === 1) {
    return `DataMarkingState-${assignee.split('@')[0]}.json`;
  } else {
    return `DataMarkingState-${assignee.split('@')[0]}-tier${tier}.json`;
  }
}

export default labelingToolLogic;
