import { transform } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import {
  upperNotesSelector,
  upperStateSelector,
} from '../../../redux/taskStateImages/stateImagesUpperSlice';
import {
  lowerNotesSelector,
  lowerStateSelector,
} from '../../../redux/taskStateImages/stateImagesLowerSlice';
import {
  xRayNotesSelector,
  xRayStateSelector,
} from '../../../redux/taskStateImages/stateImagesXRaySlice';
import { useCreateCaseIndex } from '../customHooks';
import labelingToolLogic from '../LabelingToolPage.logic';
import {
  assigneeSelector,
  isDeletedTaskSelector,
  pathSelector,
  setIsDeletedTask,
  taskLevelSelector,
  tierSelector,
} from '../../../redux/taskState/taskDetailsSlice';
import { NotificationManager } from 'react-notifications';
import userActionLogs from '../../../shared-logic/userActionLogs';
import { isImage } from '../../../shared-logic/taskLevelsTypesHelper';
import logger from '../../../logger';
import { lowerPrevTierStateSelector } from '../../../redux/taskStateImages/stateImagesLowerSlice';
import { upperPrevTierStateSelector } from '../../../redux/taskStateImages/stateImagesUpperSlice';
// TODO : support XRayPrevTierStateSelector
import { isTier2 } from '../../../shared-logic/tiersHelpers';
import { isSupervisor, isWatcher } from '../../TasksList/rolesUtil';
import { selectRole } from '../../../redux/tasks/tasksSlice';

const useSaveState = () => {
  const lowerState = useSelector(lowerStateSelector);
  const upperState = useSelector(upperStateSelector);
  const xRayState = useSelector(xRayStateSelector);

  const assignee = useSelector(assigneeSelector);
  const path = useSelector(pathSelector);
  const tier = useSelector(tierSelector);
  const upperNotes = useSelector(upperNotesSelector);
  const lowerNotes = useSelector(lowerNotesSelector);
  const xRayNotes = useSelector(xRayNotesSelector);
  const taskLevel = useSelector(taskLevelSelector);
  const isDeletedTask = useSelector(isDeletedTaskSelector);
  const lowerPrevState = useSelector(lowerPrevTierStateSelector);
  const upperPrevState = useSelector(upperPrevTierStateSelector);
  const role = useSelector(selectRole);

  const dispatch = useDispatch();
  const createIndex = useCreateCaseIndex();

  function isObjectNotEmpty(obj) {
    return obj && Object.keys(obj).length !== 0;
  }

  const saveStateLogic = async () => {
    const caseOutput = [];
    isObjectNotEmpty(lowerState) && caseOutput.push({ lower: lowerState });
    isObjectNotEmpty(upperState) && caseOutput.push({ upper: upperState });
    isObjectNotEmpty(xRayState) && caseOutput.push({ 'x-ray': xRayState });
    if (tier === 3) {
      isObjectNotEmpty(lowerPrevState) &&
        caseOutput.push({
          'lower-agg': {
            version: 1,
            imagePairs: lowerPrevState,
          },
        });
      isObjectNotEmpty(upperPrevState) &&
        caseOutput.push({
          'upper-agg': {
            version: 1,
            imagePairs: upperPrevState,
          },
        });
    }
    const outputIndex = createIndex();

    userActionLogs.addActionLog(
      'save output index:' + JSON.stringify(outputIndex)
    );
    const logs = userActionLogs.getLogs();

    const allNotes =
      (isTier2(tier) || isWatcher(role) || isSupervisor(role)) &&
      isImage(taskLevel)
        ? [...(upperNotes || []), ...(lowerNotes || []), ...(xRayNotes || [])]
        : [];

    const filteredNotes = allNotes
      .filter((x) => x.edited)
      .map(({ edited, ...rest }) =>
        transform(
          rest,
          (result, val, key) =>
            (result[key.charAt(0).toUpperCase() + key.slice(1)] = val)
        )
      );

    const res = await labelingToolLogic.saveState(
      {
        path: `${path}`,
        assignee,
        tier,
      },
      caseOutput,
      outputIndex,
      filteredNotes,
      logs
    );
    userActionLogs.addActionLog('clearing logs');
    userActionLogs.clearLogs();
    return res;
  };

  const saveAction = async (isAutoSave = false) => {
    if (isDeletedTask) {
      logger
        .info('It is not possible to save task data after deleting')
        .data({ module: 'useSaveState' })
        .end();
      dispatch(setIsDeletedTask(false));
      return;
    }
    userActionLogs.addActionLog('save action');
    try {
      await saveStateLogic();
      !isAutoSave &&
        NotificationManager.success('State Successfully saved', 'Success!');
    } catch (err) {
      NotificationManager.error(
        'an Error Occured when saving State',
        'Warning'
      );
    }
  };

  return saveAction;
};

export default useSaveState;
